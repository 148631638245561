/* unplugin-vue-components disabled */import _objectSpread from "D:\\Projects\\118_meijia\\frontend\\node_modules\\@babel\\runtime\\helpers\\esm\\objectSpread2.js";
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-4b78668f"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = ["onClick"];
import moment from "moment";
import { DatePicker } from "v-calendar";
export default {
  __name: 'time-block',
  props: {
    value: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  emits: ["update:value"],
  setup: function setup(__props, _ref) {
    var emits = _ref.emit;
    var props = __props;
    var formattedDate = computed(function () {
      if (!props.value.date) return "";
      return moment(props.value.date).format("HH:mm:ss");
    });

    function selectDate(e) {
      emits("update:value", _objectSpread(_objectSpread({}, props.value), {}, {
        date: e
      }));
    }

    function onClick() {
      if (!props.value.date) {
        emits("update:value", _objectSpread(_objectSpread({}, props.value), {}, {
          date: new Date()
        }));
      }
    }

    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", {
        onClick: onClick,
        class: "time-block"
      }, [_createVNode(_unref(DatePicker), {
        "hide-time-header": "",
        "model-value": __props.value.date,
        "onUpdate:modelValue": selectDate,
        mode: "time",
        "time-accuracy": 3,
        is24hr: ""
      }, {
        default: _withCtx(function (_ref2) {
          var togglePopover = _ref2.togglePopover;
          return [_createElementVNode("div", {
            class: "picker",
            onClick: togglePopover
          }, _toDisplayString(__props.value.date ? _unref(formattedDate) : "時間欄位"), 9, _hoisted_1)];
        }),
        _: 1
      }, 8, ["model-value"])]);
    };
  }
};