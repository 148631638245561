/* unplugin-vue-components disabled */import __unplugin_components_2 from 'D:/Projects/118_meijia/frontend/src/components/MDropdownMenu.vue';
import __unplugin_components_1 from 'D:/Projects/118_meijia/frontend/src/components/MCalendar.vue';
import __unplugin_components_0 from 'D:/Projects/118_meijia/frontend/src/components/BaseSpacer.vue';
import _objectSpread from "D:\\Projects\\118_meijia\\frontend\\node_modules\\@babel\\runtime\\helpers\\esm\\objectSpread2.js";
import { unref as _unref, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-5a5b9474"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "date-block"
};
import moment from 'moment';
export default {
  __name: 'date-block',
  props: {
    value: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  emits: ["update:value"],
  setup: function setup(__props, _ref) {
    var emits = _ref.emit;
    var props = __props;
    var formattedDate = computed(function () {
      return moment(props.value.date).format('YYYY-MM-DD');
    });

    function selectDate(e) {
      emits("update:value", _objectSpread(_objectSpread({}, props.value), {}, {
        date: e
      }));
    }

    return function (_ctx, _cache) {
      var _component_BaseSpacer = __unplugin_components_0;

      var _component_MCalendar = __unplugin_components_1;

      var _component_m_dropdown_menu = __unplugin_components_2;

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_m_dropdown_menu, null, {
        menu: _withCtx(function (slotProp) {
          return [_createVNode(_component_BaseSpacer, {
            h: "7px"
          }), _createVNode(_component_MCalendar, {
            "model-value": __props.value.date,
            "onUpdate:modelValue": function onUpdateModelValue($event) {
              slotProp.instance.close();
              selectDate($event);
            }
          }, null, 8, ["model-value", "onUpdate:modelValue"])];
        }),
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString(__props.value.date ? _unref(formattedDate) : "日期欄位") + " ", 1)];
        }),
        _: 1
      })]);
    };
  }
};