import _objectSpread from "D:\\Projects\\118_meijia\\frontend\\node_modules\\@babel\\runtime\\helpers\\esm\\objectSpread2.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.sort.js";
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-ab9c81d6"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "checkbox-block"
};
var _hoisted_2 = ["id", "value", "checked"];
export default {
  __name: 'checkbox-block',
  props: {
    value: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  emits: ["update:value"],
  setup: function setup(__props, _ref) {
    var emits = _ref.emit;
    var props = __props;

    function updateValue(e) {
      var _props$value$selected;

      var val = e.target.value;
      var selected = (_props$value$selected = props.value.selected) !== null && _props$value$selected !== void 0 ? _props$value$selected : [];

      if (selected.includes(val)) {
        var index = selected.indexOf(val);
        selected.splice(index, 1);
      } else {
        selected.push(val);
      }

      selected.sort();
      emits("update:value", _objectSpread(_objectSpread({}, props.value), {}, {
        selected: selected
      }));
    }

    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.value.options, function (option) {
        var _props$value$selecte;

        return _openBlock(), _createElementBlock("label", {
          key: option.id,
          class: "checkbox-block-option"
        }, [_createElementVNode("input", {
          id: option.id,
          type: "checkbox",
          value: option.text,
          checked: (_props$value$selecte = __props.value.selected) === null || _props$value$selecte === void 0 ? void 0 : _props$value$selecte.includes(option.text),
          onChange: updateValue
        }, null, 40, _hoisted_2), _createElementVNode("span", null, _toDisplayString(option.text), 1)]);
      }), 128))])]);
    };
  }
};