/* unplugin-vue-components disabled */import _objectSpread from "D:\\Projects\\118_meijia\\frontend\\node_modules\\@babel\\runtime\\helpers\\esm\\objectSpread2.js";
import { unref as _unref, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-01ccfaa8"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "h-100 w-100"
};
var _hoisted_2 = ["rows", "value"];
export default {
  __name: 'input-block',
  props: {
    value: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  emits: ["update:value"],
  setup: function setup(__props, _ref) {
    var emits = _ref.emit;
    var props = __props;
    var inputRef = ref();
    var maxRows = ref(1);

    function getLineCount(dom) {
      var style = window.getComputedStyle(dom);
      var lineHeight = parseFloat(style.lineHeight);
      var height = dom.scrollHeight;
      return Math.floor(height / lineHeight);
    }

    function updateValue(e) {
      var lines = getLineCount(inputRef.value);

      if (lines > maxRows.value) {
        e.target.value = props.value.text;
      } else {
        emits("update:value", _objectSpread(_objectSpread({}, props.value), {}, {
          text: e.target.value
        }));
      }
    }

    onMounted(function () {
      maxRows.value = Math.floor(inputRef.value.clientHeight / 30);
    });
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("textarea", {
        ref_key: "inputRef",
        ref: inputRef,
        rows: _unref(maxRows),
        value: __props.value.text,
        class: "input-block",
        onInput: _cache[0] || (_cache[0] = function ($event) {
          return updateValue($event);
        })
      }, null, 40, _hoisted_2), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(maxRows), function (i) {
        return _openBlock(), _createElementBlock("div", {
          key: i,
          class: "line-bg",
          style: _normalizeStyle({
            top: (i - 1) * 30 + 'px'
          })
        }, null, 4);
      }), 128))]);
    };
  }
};