/* unplugin-vue-components disabled */import __unplugin_components_0 from 'D:/Projects/118_meijia/frontend/src/components/MDropdownSelect.vue';
import _objectSpread from "D:\\Projects\\118_meijia\\frontend\\node_modules\\@babel\\runtime\\helpers\\esm\\objectSpread2.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.find-index.js";
import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-452ee5e9"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "doctor-sign-block"
};
var _hoisted_2 = {
  class: "select-label"
};
var _hoisted_3 = {
  key: 0
};
var _hoisted_4 = {
  key: 1,
  class: "preview-wrapper"
};
var _hoisted_5 = ["src"];
import { Employee } from '@/js/services/baseService';
export default {
  __name: 'doctor-signature-block',
  props: {
    value: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  emits: ["update:value"],
  setup: function setup(__props, _ref) {
    var emits = _ref.emit;
    var props = __props;

    function getDoctor() {
      Employee.doctors().then(function (data) {
        state.options = data.map(function (row) {
          return {
            name: row.name,
            value: row.id,
            img: row.image_sign
          };
        });
      });
    }

    getDoctor();
    var state = reactive({
      dropdownShow: false,
      options: []
    });
    var selectedOptions = computed(function () {
      var _props$value$selected, _props$value$selected2;

      return (_props$value$selected = (_props$value$selected2 = props.value.selected) === null || _props$value$selected2 === void 0 ? void 0 : _props$value$selected2.map(function (val) {
        var option = state.options.find(function (option) {
          return option.value === val;
        });
        return option;
      }).filter(function (val) {
        return !!val;
      })) !== null && _props$value$selected !== void 0 ? _props$value$selected : [];
    });

    function onChange(selected) {
      var result = selected.toSorted(function (a, b) {
        return state.options.findIndex(function (_ref2) {
          var value = _ref2.value;
          return value === a;
        }) - state.options.findIndex(function (_ref3) {
          var value = _ref3.value;
          return value === b;
        });
      });
      emits("update:value", _objectSpread(_objectSpread({}, props.value), {}, {
        selected: result
      }));
    }

    return function (_ctx, _cache) {
      var _component_MDropdownSelect = __unplugin_components_0;

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_MDropdownSelect, {
        show: _unref(state).dropdownShow,
        "onUpdate:show": _cache[0] || (_cache[0] = function ($event) {
          return _unref(state).dropdownShow = $event;
        }),
        options: _unref(state).options,
        multiple: "",
        "model-value": __props.value.selected,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
          return onChange($event);
        })
      }, {
        default: _withCtx(function () {
          return [_createElementVNode("div", _hoisted_2, [!_unref(selectedOptions).length ? (_openBlock(), _createElementBlock("span", _hoisted_3, "醫師簽章 ")) : (_openBlock(), _createElementBlock("div", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(selectedOptions), function (option) {
            return _openBlock(), _createElementBlock("div", {
              key: option.value,
              style: _normalizeStyle("width: ".concat(100 / _unref(selectedOptions).length, "%")),
              class: "preview-item"
            }, [_createElementVNode("img", {
              class: "preview",
              src: option.img,
              alt: ""
            }, null, 8, _hoisted_5), _createElementVNode("div", null, _toDisplayString(option.name), 1)], 4);
          }), 128))]))])];
        }),
        _: 1
      }, 8, ["show", "options", "model-value"])]);
    };
  }
};