/* unplugin-vue-components disabled */import _objectSpread from "D:\\Projects\\118_meijia\\frontend\\node_modules\\@babel\\runtime\\helpers\\esm\\objectSpread2.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.match.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-38470c26"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = ["value", "step"];
export default {
  __name: 'input-number-block',
  props: {
    value: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  emits: ["update:value"],
  setup: function setup(__props, _ref) {
    var emits = _ref.emit;
    var props = __props;
    var spinButtonClicked = ref(false);

    function getStep(val) {
      var regex = /^\d+(\.(\d+))?$/;
      var str = val + "";
      var matches = str === null || str === void 0 ? void 0 : str.match(regex);

      if (matches !== null && matches !== void 0 && matches[2]) {
        return 1 / Math.pow(10, matches[2].length);
      }

      return 1;
    }

    var step = computed(function () {
      return getStep(props.value.text);
    });

    function onKeydown(e) {
      if (e.key === "ArrowUp" || e.key === "ArrowDown") {
        spinButtonClicked.value = true;
      }
    }

    function onKeyup() {
      spinButtonClicked.value = false;
    }

    function mousedown() {
      spinButtonClicked.value = true;
    }

    function mouseup() {
      spinButtonClicked.value = false;
    }

    function updateValue(e) {
      var newStep = getStep(e.target.value);

      if (spinButtonClicked.value && step.value !== newStep) {
        var hasDecimal = e.target.value.includes(".");
        e.target.value += hasDecimal ? "0" : ".0";
      }

      emits("update:value", _objectSpread(_objectSpread({}, props.value), {}, {
        text: e.target.value
      }));
    }

    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("input", {
        type: "number",
        class: "input-number-block",
        value: __props.value.text,
        step: _unref(step),
        onInput: _cache[0] || (_cache[0] = function ($event) {
          return updateValue($event);
        }),
        onKeydown: onKeydown,
        onKeyup: onKeyup,
        onMousedown: mousedown,
        onMouseup: mouseup,
        inputmode: "decimal"
      }, null, 40, _hoisted_1);
    };
  }
};